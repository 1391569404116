import React from "react"
import { Link } from "gatsby"
import '../styles/utilitary.css'
import '../styles/medalhoes.css'
import Layout from "../components/layout"
import SEO from "../components/seo"
import logo from "../images/logo.png" // Tell webpack this JS file uses this image
import gravlaxcover from "../images/Blinis.jpg" // Tell webpack this JS file uses this image
import bacicones from "../images/bac-icones.png" // Tell webpack this JS file uses this image
import bacicones2 from "../images/bac-icones2.png" // Tell webpack this JS file uses this image
import logonew from "../images/logo-new-salmon.png" // Tell webpack this JS file uses this image
import diff1 from "../images/diff-1.png" // Tell webpack this JS file uses this image
import diff2 from "../images/diff-2.png" // Tell webpack this JS file uses this image
import diff3 from "../images/diff-3.png" // Tell webpack this JS file uses this image
import price1 from "../images/price-1.png" // Tell webpack this JS file uses this image
import price2 from "../images/price-2.png" // Tell webpack this JS file uses this image
import price3 from "../images/price-3.png" // Tell webpack this JS file uses this image
import time from "../images/time.png" // Tell webpack this JS file uses this image
import pessoas from "../images/pessoas.png" // Tell webpack this JS file uses this image


import ingrediente from "../images/ingrediente.svg" // Tell webpack this JS file uses this image
import StopMotionGravlax from "../images/StopMotionMedalhaolimaosalsa.mp4"
const Medalhoes = () => (
<Layout>
   <SEO title="Peixe Fácil - Medalhões de Salmão" />
   <div className="wrapper-bg-medalhoes">
      <div className="center-col-bg">
      <div className="logo-brand">
            <img src={logonew} alt="" />
         </div>
      </div>
   </div>
   <div id="section-1-medalhoes">
      <div className="wrapper-container">
        
         <div className="titulo absara blue-marine">
            <p>Medalhões de Salmão</p>
         </div>
         <div className="descricao sneak-regular opacity">
            <p> Sem ingredientes adicionados, os medalhões de salmão, permitem-lhe economizar tempo e manter um estilo de vida saudável!</p>
         </div>
      </div>
   </div>
   <div id="recipes-section-medalhoes">
      <div className="wrapper-section">
        <div className="col-1">
        <video className="video-class" preload='auto' controls autoPlay loop playsInline muted>
            <source src={StopMotionGravlax} type="video/mp4" />
          </video>
        </div>
        <div className="col-2">
        <div className="wrapper">
           <div className="card-recipe">
              
              <div className="top">
              <p className="title absara"> 
                 Medalhões de Salmão com limão e salsa
                 </p>
                 
              </div>
              <div className="middle">
              <div className="col1">
                <span className="absara"> 2 Pessoas</span><br></br>
              <img src={pessoas} alt="" />

                
                </div>
                <div className="col2">
                <span className="absara"> 20 Minutos</span><br></br>
                <img src={time} alt="" />

                  </div>
                  <div className="col3">
                  <span className="absara"> Dificuldade</span><br></br>
                  <img src={diff1} alt="" />

                  </div>
                  <div className="col4">
                  <span className="absara"> Preço</span><br></br>
                  <img src={price1} alt="" />

                  </div>
              </div>
              <div className="bottom">
                  <div className="left-col">
                     <div className="card-ingedientes">
                      <span className="titulo absara">Ingredientes</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Emb. de Medalhões de Salmão</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Limão</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Dente de alho</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">20g de Manteiga</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Azeite Q/B</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Salsa Q/B</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Sal e pimenta Q/B</span><br></br>
                    
                     </div>
                  </div>
                  <div className="right-col">
                       <div className="card-receita">
                       <span className="titulo absara">Receita</span><br></br>
                       <span className="span-icon">1 -</span><span className="span-text">Pique a salsa e esprema o limão para uma taça. Misture e reserve.</span><br></br>
                       <span className="span-icon">2 -</span><span className="span-text">Salteie os medalhões de salmão numa frigideira anti-aderente durante cerca de 5 minutos de cada lado em lume médio com azeite.</span><br></br>
                       <span className="span-icon">3 -</span><span className="span-text">Baixe o lume. Junte a manteiga e deixe derreter. Adicione o sumo de limão e a salsa e deixe cozinhar cerca de 3 minutos.</span><br></br>
                       <span className="span-icon">4 -</span><span className="span-text">Tempere a gosto.</span><br></br>
                       <span className="span-icon">5 -</span><span className="span-text">Sirva com massa, arroz ou legumes.</span><br></br>


                     </div>
                     </div>
                 </div>

                 <div className="section-nutri">
                  <div className="caracts">
            
            <div className="wrapper">
           
                <div className="card-caracts">
                  
                <div className="main-titulo absara">
                   Valores Nutricionais
                </div>
                <div className="contentor">
                <div className="col-1-nutri">
                        <div className="titulo absara">
                            Energia Kcal
                        </div>
                        <div className="titulo absara">
                            Lipidos g
                        </div>
                        <div className="titulo absara">
                            Saturados g
                        </div>
                        <div className="titulo absara">
                            Hidratos g
                        </div>
                        <div className="titulo absara">
                            Açucares g
                        </div>
                        <div className="titulo absara">
                            Proteinas g
                        </div>
                        <div className="titulo absara">
                            Fibra g
                        </div>
                     
                    </div>
                    <div className="col-2-nutri">
                    <div className="titulo sneak-regular">
                            434
                        </div>
                        <div className="titulo sneak-regular">
                            39
                        </div>
                        <div className="titulo sneak-regular">
                          10
                        </div>
                        <div className="titulo sneak-regular">
                     1
                        </div>
                        <div className="titulo sneak-regular">
                         1
                        </div>
                        <div className="titulo sneak-regular">
                         20
                        </div>
                        <div className="titulo sneak-regular">
                        1
                        </div>
                       
                        </div>
                </div>
                   
                  
                    
                    
                </div>
            </div>
        </div>
    </div>
           </div>
        </div>
        </div>
      </div>

      <div className="wrapper-section-2">
        <div className="col-1-mobile">

        </div>
        <div className="col-1">
        <div className="wrapper">
           <div className="card-recipe">
              
              <div className="top">
              <p className="title absara"> 
                 Medalhões de Salmão no forno com legumes
                 </p>
              </div>
              <div className="middle">
              <div className="col1">
                <span className="absara"> 2 Pessoas</span><br></br>
              <img src={pessoas} alt="" />

                
                </div>
                <div className="col2">
                <span className="absara"> 35 Minutos</span><br></br>
                <img src={time} alt="" />

                  </div>
                  <div className="col3">
                  <span className="absara"> Dificuldade</span><br></br>
                  <img src={diff1} alt="" />

                  </div>
                  <div className="col4">
                  <span className="absara"> Preço</span><br></br>
                  <img src={price1} alt="" />

                  </div>
              </div>
              <div className="bottom">
                  <div className="left-col">
                     <div className="card-ingedientes">
                        <span className="titulo absara">Ingredientes</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Emb. de Medalhões de Salmão</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Cebola</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Dente de alho</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">3 Courgette</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Beringela</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Pimento verde</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">200g de Cogumelos</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Azeite Q/B</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Salsa Q/B</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Sal e pimenta Q/B</span><br></br>

                     </div>
                  </div>
                  <div className="right-col">
                       <div className="card-receita">
                       <span className="titulo absara">Receita</span><br></br>
                       <span className="span-icon">1 -</span><span className="span-text">Lamine a cebola e o alho.</span><br></br>
                       <span className="span-icon">2 -</span><span className="span-text">Corte a courgete, os pimentos e a beringela em cubos pequenos.</span><br></br>
                       <span className="span-icon">3 -</span><span className="span-text">Numa tigela, junte tudo com os cogumelos cortados em quartos, a cebola e o alho e tempere com azeite, sal e pimenta a gosto.</span><br></br>
                       <span className="span-icon">4 -</span><span className="span-text">Coloque os legumes num tabuleiro, e disponha os medalhões de salmão por cima, e tempere os mesmos.</span><br></br>
                       <span className="span-icon">5 -</span><span className="span-text">Asse no forno pré aquecido a 180ª graus durante 20 minutos.</span><br></br>
                       <span className="span-icon">6 -</span><span className="span-text">Retire do forno e sirva polvilhado com salsa picada.</span><br></br>


                     </div>
                     </div>
                 </div>

                 <div className="section-nutri">
                  <div className="caracts">
            
            <div className="wrapper">
           
                <div className="card-caracts">
                  
                <div className="main-titulo absara">
                   Valores Nutricionais
                </div>
                <div className="contentor">
                <div className="col-1-nutri">
                        <div className="titulo absara">
                            Energia Kcal
                        </div>
                        <div className="titulo absara">
                            Lipidos g
                        </div>
                        <div className="titulo absara">
                            Saturados g
                        </div>
                        <div className="titulo absara">
                            Hidratos g
                        </div>
                        <div className="titulo absara">
                            Açucares g
                        </div>
                        <div className="titulo absara">
                            Proteinas g
                        </div>
                        <div className="titulo absara">
                            Fibra g
                        </div>
                     
                    </div>
                    <div className="col-2-nutri">
                    <div className="titulo sneak-regular">
                            453
                        </div>
                        <div className="titulo sneak-regular">
                            33
                        </div>
                        <div className="titulo sneak-regular">
                          6
                        </div>
                        <div className="titulo sneak-regular">
                      11
                        </div>
                        <div className="titulo sneak-regular">
                         10
                        </div>
                        <div className="titulo sneak-regular">
                         28
                        </div>
                        <div className="titulo sneak-regular">
                        10
                        </div>
                       
                        </div>
                </div>
                   
                  
                    
                    
                </div>
            </div>
        </div>
    </div>
           </div>
        </div>
        </div>
        <div className="col-2">
 
        </div>
      </div>

      <div className="wrapper-section-3">
      <div className="col-1">
 
 </div>
        <div className="col-2">
        <div className="wrapper">
           <div className="card-recipe">
              
              <div className="top">
              <p className="title absara"> 
                 Hambúrguer de Salmão
              </p>
              </div>
              <div className="middle">
              <div className="col1">
                <span className="absara"> 2 Pessoas</span><br></br>
              <img src={pessoas} alt="" />

                
                </div>
                <div className="col2">
                <span className="absara"> 40 Minutos</span><br></br>
                <img src={time} alt="" />

                  </div>
                  <div className="col3">
                  <span className="absara"> Dificuldade</span><br></br>
                  <img src={diff2} alt="" />

                  </div>
                  <div className="col4">
                  <span className="absara"> Preço</span><br></br>
                  <img src={price2} alt="" />

                  </div>
              </div>
              <div className="bottom">
                  <div className="left-col">
                     <div className="card-ingedientes">
                        <span className="titulo absara">Ingredientes</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Emb. de Medalhões de Salmão</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">2 Pães para hambúrguer</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">30g de rúcula selvagem </span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Abacate</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1/2 Cebola roxa</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Tomate</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">50g de Maionese</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Cebolinho Q/B</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Lima</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Azeite Q/B</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Sal e pimenta Q/B</span><br></br>




                     </div>
                  </div>
                  <div className="right-col">
                       <div className="card-receita">
                       <span className="titulo absara">Receita</span><br></br>
                       <span className="span-icon">1 -</span><span className="span-text">Pique o cebolinho e misture com a maionese e raspas de lima. Reserve.</span><br></br>
                       <span className="span-icon">2 -</span><span className="span-text">Salteie os medalhões de salmão numa frigideira anti-aderente durante cerca de 5 minutos de cada lado em lume médio com azeite e tempere.</span><br></br>
                       <span className="span-icon">3 -</span><span className="span-text">Enquanto os medalhões estão ao lume, corte o abacate em lamelas finas, o tomate ás rodelas e pique a cebola roxa.</span><br></br>
                       <span className="span-icon">4 -</span><span className="span-text">Barre o pão com a maionese e adicione a rúcula, o tomate, os medalhões, o abacate e a cebola picada.</span><br></br>
                       <span className="span-icon">5 -</span><span className="span-text">Sirva com uma salada verde ou batatas fritas.</span><br></br>


                     </div>
                     </div>
                 </div>
                 <div className="section-nutri">
                  <div className="caracts">
            
            <div className="wrapper">
           
                <div className="card-caracts">
                  
                <div className="main-titulo absara">
                   Valores Nutricionais
                </div>
                <div className="contentor">
                <div className="col-1-nutri">
                        <div className="titulo absara">
                            Energia Kcal
                        </div>
                        <div className="titulo absara">
                            Lipidos g
                        </div>
                        <div className="titulo absara">
                            Saturados g
                        </div>
                        <div className="titulo absara">
                            Hidratos g
                        </div>
                        <div className="titulo absara">
                            Açucares g
                        </div>
                        <div className="titulo absara">
                            Proteinas g
                        </div>
                        <div className="titulo absara">
                            Fibra g
                        </div>
                     
                    </div>
                    <div className="col-2-nutri">
                    <div className="titulo sneak-regular">
                            901
                        </div>
                        <div className="titulo sneak-regular">
                           71
                        </div>
                        <div className="titulo sneak-regular">
                          13
                        </div>
                        <div className="titulo sneak-regular">
                      35
                        </div>
                        <div className="titulo sneak-regular">
                        13
                        </div>
                        <div className="titulo sneak-regular">
                         30
                        </div>
                        <div className="titulo sneak-regular">
                        11
                        </div>
                       
                        </div>
                </div>
                   
                  
                    
                    
                </div>
            </div>
        </div>
    </div>
           </div>
        </div>
        </div>
       
      </div>
   </div>
   <div className="wrapper-3-medalhoes">
   <div className="logo-footer">
      <div className="logo-brand">
            <img src={logonew} alt="" />
         </div>

      </div>
      <div className="btn-div">
         <Link to="/" style={{ textDecoration: 'none' }}> <button className="btn sneak-bold">Descubra outros produtos!</button></Link>
      </div>
   </div>


</Layout>
)
export default Medalhoes